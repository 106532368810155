import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProjectModel } from '../models/project.model';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './baseRepository.service';
import { SponsorshipTypeModel } from '../models/sponsorshipType.model';

@Injectable({
    providedIn: 'root'
})
export class SponsorshipTypeRepository extends BaseRepository<SponsorshipTypeModel> {
    constructor(protected http: HttpClient) {
        super(http, "SponsorshipType")
    }
}
