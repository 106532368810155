import { NgModule,APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule,HashLocationStrategy,LocationStrategy  } from '@angular/common';  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DataModule } from './data/data.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {DateValueComponent} from 'src/app/views/partials/table-values/date-value.component';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './helpers';
import { AuthenticationService } from './data/repositories/authentication.service';
import { PaymentModule } from './views/partials/payments/payment-profile.module';
import { ChildSponsorTableModule } from './views/partials/childSponsorsTable/childsponsor-table.module'; 
import {PaymentDetailTableModule} from './views/partials/paymentDetailTable/paymentdetail-table.module';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';



@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    DateValueComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    Ng2SmartTableModule,
    DataModule,
    FontAwesomeModule,
    PaymentModule,
    ChildSponsorTableModule,
    PaymentDetailTableModule
  ],
  providers: [
     {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //fakeBackendProvider
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
