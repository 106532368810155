import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProjectModel } from '../models/project.model';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './baseRepository.service';
import { AuthenticationArgs } from '../models/authenticationArgs.model';
import { ResetPasswordArgs } from '../models/resetPasswordArgs.model';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserRepository extends BaseRepository<User> {
    constructor(protected http: HttpClient) {
        super(http, "User")
    }
}
