import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProjectModel } from '../models/project.model';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './baseRepository.service';
import { PaymentDetailModel } from '../models/paymentDetail.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentDetailRepository extends BaseRepository<PaymentDetailModel> {
    constructor(protected http: HttpClient) {
        super(http, "PaymentDetail")
    }
    public GetBySponsorAndChild(childId, sponsorId){
        return this.http.get(this.apiUrl + 'GetBySponsorAndChild?ChildId='+ childId + "&SponsorId=" + sponsorId,{ withCredentials: true }).pipe(map(res => res));
    }
}
