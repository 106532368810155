import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router'


import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


import { ChildrenModel } from 'src/app/data/models/children.model';
import {ChildrenRepository} from 'src/app/data/repositories/childrenRepository.service';

import {PaymentModel} from 'src/app/data/models/payment.model';
import {PaymentRepository} from 'src/app/data/repositories/paymentRepository.service';


import {SponsorModel} from 'src/app/data/models/sponsor.model';

import {ReceiptCategoryModel} from 'src/app/data/models/receiptCategory.model';
import {ReceiptCategoryRepository} from 'src/app/data/repositories/receiptsCategoriesRepository.service';

import {SponsorshipTypeModel} from 'src/app/data/models/sponsorshipType.model';
import {SponsorshipTypeRepository} from 'src/app/data/repositories/sponsorshipTypeRepository.service';

import Swal from 'sweetalert2';
import { variable } from '@angular/compiler/src/output/output_ast';
import { DateValueComponent } from '../table-values/date-value.component';
// import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'payment',
  templateUrl: './payment-profile.component.html',
  styleUrls: ['./payment-profile.component.scss']
})
export class PaymentComponent implements OnInit {

  dataSource: PaymentModel[] = []
  
  dataForm: FormGroup;
  saving: boolean = false;
  isEdit: boolean = false;
  title: string = "Add New"
  selectedItem: PaymentModel;
  modal: NgbModalRef;

  receiptCategories: ReceiptCategoryModel[] = [];
  sponsorshipTypes: SponsorshipTypeModel[] = [];

  @Input()
  sponsors: SponsorModel[];

  @Input()
  children: ChildrenModel[];

  @Input()
  id: number;

  @Input()
  sponsorView: boolean = false;

  sub: any;

  @ViewChild('dataModal') dataModal;
  constructor(private route: ActivatedRoute, 
    private paymentRepo: PaymentRepository,
    private recieptCatRepo: ReceiptCategoryRepository,
    private sponsorshipTypeRepo: SponsorshipTypeRepository, 
    public fb: FormBuilder, public modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.GetFeatures();
  }

  private GetDataSource(){

    this.paymentRepo.GetBySponsorId(this.id).subscribe(res => {
      var data = res as PaymentModel[];
      
      data.forEach((pm) => {
        pm.sponsorshipTypeName = '';
          if(pm.sponsorshipTypeID != null){
            let spType = this.sponsorshipTypes.find(o => o.id == pm.sponsorshipTypeID);
            if(spType){
              pm.sponsorshipTypeName = spType.arName;
            }
          }
        });


      this.dataSource = data;
    });

  }

  private GetFeatures(){
    this.sponsorshipTypeRepo.GetAll().subscribe(res => {
      var data = res as SponsorshipTypeModel[];
      this.sponsorshipTypes = data;

      this.GetDataSource();
    });
    this.recieptCatRepo.GetAll().subscribe(res => {
      var data = res as ReceiptCategoryModel[];
      this.receiptCategories = data;
    });
  }

  settings = {
    mode: 'external',
    defaultStyle: false,
    attr:{
      class: 'table table-bordered'
    },
    columns: {
      id: {
        title: '#',
        filter: false,
      },
      receiptNo:{
        title: 'رقم الوصل'
      },
      receiptDate:{
        title: 'تاريخ الوصل',
        type: 'custom',
        renderComponent: DateValueComponent
      },
      sponsorshipTypeName:{
        title: ' نوع التبرع-الكفالة'

      },
      paymentAmount:{
        title: "المبلغ",
        filter: true
      }
     
    },
    actions: {
      columnTitle: "", 
      add: true, edit: true, delete: true,  
      position: 'right', 

    },
    add: {
      addButtonContent: 'إضافة',
    },
    edit: {
      editButtonContent: '<i data-feather="edit" appFeatherIcon class="btn-icon-prepend"></i> تعديل'},
      delete: {
        deleteButtonContent: '<i data-feather="trash" appFeatherIcon class="btn-icon-prepend"></i> حذف'},
    pager:{
      display: true,
      perPage: 100
    }
  };

  private reactiveServiceForm(){
    if(this.isEdit){
      console.log('editing');
      this.dataForm = this.fb.group({
        targetId: new FormControl( this.sponsorView ? this.children.find(o => o.id === this.selectedItem.benefitedChilds) : this.sponsors.find(o => o.id === this.selectedItem.sponsorId)),
        receiptCategoryId: new FormControl(this.receiptCategories.find(o => o.id === this.selectedItem.receiptCategoyID)),
        sponsorshipTypeId: new FormControl(this.sponsorshipTypes.find(o => o.id === this.selectedItem.sponsorshipTypeID)),
        receiptNo: new FormControl(this.selectedItem.receiptNo),
        receiptDate: new FormControl(new Date(this.selectedItem.receiptDate).toISOString().split('T')[0]),
        paymentAmount: new FormControl(this.selectedItem.paymentAmount),
        notes: new FormControl(this.selectedItem.notes),
        paymentDuration: new FormControl(null)
      });
    }else{
      this.dataForm = this.fb.group({
        targetId: new FormControl( this.sponsorView ? this.children[0] : this.sponsors[0], Validators.required),
        receiptCategoryId: new FormControl(this.receiptCategories[0], Validators.required),
        sponsorshipTypeId: new FormControl(this.sponsorshipTypes[0], Validators.required),
        receiptNo: new FormControl(null),
        receiptDate: new FormControl(new Date(Date.now()).toISOString().split('T')[0]),
        paymentAmount: new FormControl(this.receiptCategories[0].value),
        notes: new FormControl(null),
        paymentDuration: new FormControl(null, Validators.required)
      });
    }
  }

  public CreateNewRow(){
    this.saving = false;
    this.isEdit = false;

    this.reactiveServiceForm();
   
    this.selectedItem={
      id:0, sponsorId: 0, receiptCategoyID:0, sponsorshipTypeID: 0, benefitedChilds:0

    };
    this.title = "إضافة ";
    if(this.modalService.hasOpenModals()) {this.modalService.dismissAll();}
    this.modal = this.modalService.open(this.dataModal, { size: 'md', backdrop: 'static' });
    this.modal.result.finally(() => this.GetDataSource());
  }

  Edit(data) {
    this.saving = false;
    this.isEdit = true;
    
    // this.selectedItem = data;
    this.reactiveServiceForm();



    this.title = "تعديل ";

    if (this.modalService.hasOpenModals()) { this.modalService.dismissAll(); }
    this.modal = this.modalService.open(this.dataModal, { size: 'md', backdrop: 'static' });
  }

  getShowField(){
    return !this.dataForm.controls['receiptCategoryId'].value.isBank;
  }



  public Delete(item) {
    Swal.fire({
      title: 'هل أنت متأكد أنك تريد الحذف ',
      text: 'لن تستطيع استعادته',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'نعم ، احذف!',
      cancelButtonText: "إلغاء"
    }).then(result => {
      if(result.isConfirmed){
        console.log(item.data.id);
        this.paymentRepo.Delete(item.data.id).subscribe(res => {
          Swal.fire('تم الحذف !', 'تمت عملية حذف العنصر بنجاح', 'success').finally(() => { this.GetDataSource(); });
        }),
          error => Swal.fire('حدثت مشكلة أثناء الحذف! قم بالتأكد من وصولك للانترنت', error, 'error');
      }
    });
  }
  public ItemSelected(item) {
    this.selectedItem = item.data;
  }
  cancel() { this.modalService.dismissAll();  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.dataForm.controls) {
      this.dataForm.controls[c].markAsTouched();
    }
    if (this.dataForm.valid) {

      this.saving = true;

      // this.selectedItem.benefitedChilds = this.sponsorView ? this.dataForm.controls['targetId'].value.id : this.id;
      this.selectedItem.sponsorId = this.sponsorView ? this.id : this.dataForm.controls['targetId'].value.id;
      this.selectedItem.sponsorshipTypeID = this.dataForm.controls['sponsorshipTypeId'].value.id;
      this.selectedItem.receiptDate = this.dataForm.controls['receiptDate'].value;
      this.selectedItem.receiptCategoyID = this.dataForm.controls['receiptCategoryId'].value.id;
      this.selectedItem.notes = this.dataForm.controls['notes'].value;
      this.selectedItem.paymentAmount = this.dataForm.controls['receiptCategoryId'].value.isBank ? this.dataForm.controls['paymentAmount'].value : this.dataForm.controls['receiptCategoryId'].value.value;
      this.selectedItem.receiptNo = this.dataForm.controls['receiptNo'].value;
      this.selectedItem.paymentDuration = this.dataForm.controls['paymentDuration'].value;

    
      
      if (!this.isEdit) {
        this.paymentRepo.Add(this.selectedItem).subscribe(res => {
          this.saving = false;
          Swal.fire('تم إضافة عنصر جديد!', 'تمت االإضافة بنجاح', 'success');
          this.GetDataSource();
          this.modal.dismiss();
        },
          error => {
            console.log(error);
            Swal.fire('حدثت مشكلة', 'قد يكون سبب المشكلة هو تكرار نفس الدفعة ', 'error');
            this.saving = false;
          });
      } else {
        this.paymentRepo.Update(this.selectedItem).subscribe(res => {
          this.saving = false;
          Swal.fire('تم التعديل!', 'عملية التعديل تمت بنجاح', 'success');
          this.GetDataSource();
          this.modal.dismiss();
        },
          error => {
            Swal.fire('حدثت مشكلة', error, 'error');
            this.saving = false;
          });
      }
    }
  }



}
