import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router'


import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


import { ChildrenModel } from 'src/app/data/models/children.model';
import {ChildrenRepository} from 'src/app/data/repositories/childrenRepository.service';

import {PaymentModel} from 'src/app/data/models/payment.model';
import {PaymentRepository} from 'src/app/data/repositories/paymentRepository.service';


import {SponsorModel} from 'src/app/data/models/sponsor.model';

import {ReceiptCategoryModel} from 'src/app/data/models/receiptCategory.model';
import {ReceiptCategoryRepository} from 'src/app/data/repositories/receiptsCategoriesRepository.service';

import {SponsorshipTypeModel} from 'src/app/data/models/sponsorshipType.model';
import {SponsorshipTypeRepository} from 'src/app/data/repositories/sponsorshipTypeRepository.service';

import { ChildSponsorModel } from 'src/app/data/models/childSponsor.model';
import { ChildSponsorRepository } from 'src/app/data/repositories/childSponsorRepository.service';

import Swal from 'sweetalert2';
import { variable } from '@angular/compiler/src/output/output_ast';

import { DateValueComponent } from '../table-values/date-value.component';
// import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'child-sponsor',
  templateUrl: './childsponsor-table.component.html',
  styleUrls: ['./childsponsor-table.component.scss']
})
export class ChildSponsorTableComponent implements OnInit {

  dataSource: ChildSponsorModel[] = []
  
  dataForm: FormGroup;
  saving: boolean = false;
  isEdit: boolean = false;
  title: string = "Add New"
  selectedItem: ChildSponsorModel;
  modal: NgbModalRef;

  sponsorshipTypes: SponsorshipTypeModel[] = [];
  sponsorshipTypesFilter = []


  @Input()
  sponsors: SponsorModel[];

  @Input()
  children: ChildrenModel[];

  @Input()
  filteredChildren: ChildrenModel[];

  @Input()
  id: number;

  @Input()
  active: boolean = true;

  @Input()
  sponsorView: boolean;

  sub: any;
  cardTitle: string = 'الأطفال المكفولين';

  settings: any;

  @ViewChild('paymentDetailModal') paymentDetailModal;
  @ViewChild('dataModal') dataModal;
  constructor(private route: ActivatedRoute, 
    private childSponsorRepo: ChildSponsorRepository,
    private sponsorshipTypeRepo: SponsorshipTypeRepository, 
    public fb: FormBuilder, public modalService: NgbModal
    ) { }

  ngOnInit(): void {    
    this.GetFeatures();

    if(!this.sponsorView){
      this.cardTitle = 'الكفلاء';
    }

    this.settings = {
      mode: 'external',
      defaultStyle: false,
      attr:{
        class: 'table table-bordered'
      },
      columns: {
        id: {
          title: '#',
          filter: false,
        },
        targetName:{
          title: this.sponsorView ? "الطفل" : "الكفيل",
          filter: true
        },
        sponsorshipTypeName:{
          title: "نوع الكفالة",
          // filter: {
          //   type: 'list',
          //   config: {
          //     selectText: 'اختر..',
          //     list: this.sponsorshipTypesFilter
          //   },
          // }
        },
        sponsorshipStart:{
          title: "بدء الكفالة",
          type: "custom",
          renderComponent: DateValueComponent
        },
        annualSponsorship: {
          title: "الكفالة السنوية",
          filter: false
        }
      },
      actions: {
        columnTitle: "", 
        add: this.sponsorView, edit: this.sponsorView, delete: this.sponsorView,  
        position: 'right', 
        custom: this.sponsorView ? [] : [
          {
            name: 'whatsapp',
            title: '<img class="profile-pic" src="assets/images/whatsapp.png" alt="whatsapp">',
          }
        ],
      },
      add: {
        addButtonContent: 'إضافة',
      },
      edit: {
        editButtonContent: '<i data-feather="edit" appFeatherIcon class="btn-icon-prepend"></i> تعديل'},
        delete: {
          deleteButtonContent: '<i data-feather="trash" appFeatherIcon class="btn-icon-prepend"></i> حذف'},
      pager:{
        display: true,
        perPage: 100
      }
    };
  }

  private GetDataSource(){
    
      (this.sponsorView ? this.childSponsorRepo.GetBySponsorId(this.id, this.active): this.childSponsorRepo.GetByChildId(this.id, this.active)).subscribe(res => {
        var data = res as ChildSponsorModel[];
        
        data.forEach((sp) => {
          // sp.sponsorshipTypeName = this.sponsorshipTypes.find(o => o.id == sp.sponsorshipTypeId).arName;
          
          sp.sponsorshipTypeName = ''
          let spType = this.sponsorshipTypes.find(o => o.id == sp.sponsorshipTypeId);
          if(spType){
            sp.sponsorshipTypeName = spType.arName;
          }
          
          if(this.sponsorView){
            sp.targetName = sp.childName;
          }else{
            sp.targetName = sp.sponsorName;
          }
        });

        this.dataSource = data;
      });
  }

  private GetFeatures(){
    this.sponsorshipTypeRepo.GetAll().subscribe(res => {
      var data = res as SponsorshipTypeModel[];
      this.sponsorshipTypes = data;

      this.sponsorshipTypes.forEach((type)=> {
        this.sponsorshipTypesFilter.push(
          {title: type.arName, value: type.arName}
        );
      });

      this.GetDataSource();
    });
  }

  public ShowPaymentDetail(){
    this.title = "تفاصيل الدفعات";
    if(this.modalService.hasOpenModals()) {this.modalService.dismissAll();}
    this.modal = this.modalService.open(this.paymentDetailModal, { size: 'md', backdrop: 'static' });
    // this.modal.result.finally(() => this.GetDataSource());
  }

  

  private reactiveServiceForm(){
    if(this.isEdit){
      this.dataForm = this.fb.group({
        childId: new FormControl(this.children.find(o => o.id == this.selectedItem.childId), Validators.required),
        annualSponsorship: new FormControl(this.selectedItem.annualSponsorship, Validators.required),
        sponsorshipTypeId: new FormControl(this.sponsorshipTypes.find(o => o.id === this.selectedItem.sponsorshipTypeId), Validators.required),
        sponsorshipStart: new FormControl(new Date(this.selectedItem.sponsorshipStart).toISOString().split('T')[0], Validators.required),
        sponsorshipEnd: new FormControl(new Date(this.selectedItem.sponsorshipEnd).toISOString().split('T')[0]),
        isActive: new FormControl(this.selectedItem.isActive, Validators.required)
      });
    }else{
      this.dataForm = this.fb.group({
        childId: new FormControl(this.filteredChildren[0], Validators.required),
        annualSponsorship: new FormControl(null, Validators.required),
        sponsorshipTypeId: new FormControl(this.sponsorshipTypes[0], Validators.required),
        sponsorshipStart: new FormControl(null, Validators.required),
        sponsorshipEnd: new FormControl(null),
        isActive: new FormControl(null, Validators.required)
      });
    }
  }

  public CreateNewRow(){
    this.saving = false;
    this.isEdit = false;

    this.reactiveServiceForm();
   
    this.selectedItem={
      id:0, sponsorId: 0, childId: 0, annualSponsorship: 0, sponsorshipStart: new Date(1,1,1000), isActive: true,
      sponsorshipTypeId: 0
    };
    this.title = "إضافة ";
    if(this.modalService.hasOpenModals()) {this.modalService.dismissAll();}
    this.modal = this.modalService.open(this.dataModal, { size: 'md', backdrop: 'static' });
    this.modal.result.finally(() => this.GetDataSource());
  }

  Edit(data) {
    this.saving = false;
    this.isEdit = true;
    
    // this.selectedItem = data;
    this.reactiveServiceForm();

    this.title = "تعديل ";

    if (this.modalService.hasOpenModals()) { this.modalService.dismissAll(); }
    this.modal = this.modalService.open(this.dataModal, { size: 'md', backdrop: 'static' });
  }

  onCustomEvent(event) {
    if(event.action == 'whatsapp'){
      var mobile = event.data.sponsorNumber;
      if(mobile == ''){
        alert(`This sponsor has no mobile number.`)
      }else{
        mobile = mobile.substring(1);
      
        window.open("https://wa.me/963" + mobile, "_blank");
      }
    
    }
    
  }



  public Delete(item) {
    Swal.fire({
      title: 'هل أنت متأكد أنك تريد الحذف ',
      text: 'لن تستطيع استعادته',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'نعم ، احذف!',
      cancelButtonText: "إلغاء"
    }).then(result => {
      if(result.isConfirmed){
        console.log(item.data.id);
        this.childSponsorRepo.Delete(item.data.id).subscribe(res => {
          Swal.fire('تم الحذف !', 'تمت عملية حذف العنصر بنجاح', 'success').finally(() => { this.GetDataSource(); });
        }),
        error => Swal.fire('حدثت مشكلة أثناء الحذف! قم بالتأكد من وصولك للانترنت', error, 'error');
     }
    });
  }
  public ItemSelected(item) {
    let firstTime = false;
    if(this.selectedItem == null){
      firstTime = true;
    }
    this.selectedItem = item.data;

    if(!firstTime && !this.sponsorView){
      this.ShowPaymentDetail();
    }
  }
  cancel() { this.modalService.dismissAll();  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.dataForm.controls) {
      this.dataForm.controls[c].markAsTouched();
    }
    if (this.dataForm.valid) {

      this.saving = true;

      this.selectedItem.sponsorId = this.id;
      this.selectedItem.childId = this.dataForm.controls['childId'].value.id;
      this.selectedItem.annualSponsorship = this.dataForm.controls['annualSponsorship'].value;
      this.selectedItem.sponsorshipStart = this.dataForm.controls['sponsorshipStart'].value;
      this.selectedItem.sponsorshipEnd = this.dataForm.controls['sponsorshipEnd'].value;
      this.selectedItem.isActive = this.dataForm.controls['isActive'].value;
      this.selectedItem.sponsorshipTypeId = this.dataForm.controls['sponsorshipTypeId'].value.id;
            
      if (!this.isEdit) {
        this.childSponsorRepo.Add(this.selectedItem).subscribe(res => {
          this.saving = false;
          Swal.fire('تم إضافة عنصر جديد!', 'تمت االإضافة بنجاح', 'success');
          this.GetDataSource();
          this.modal.dismiss();
        },
          error => {
            Swal.fire('حدثت مشكلة', error, 'error');
            this.saving = false;
          });
      } else {
        this.childSponsorRepo.Update(this.selectedItem).subscribe(res => {
          this.saving = false;
          Swal.fire('تم التعديل!', 'عملية التعديل تمت بنجاح', 'success');
          this.GetDataSource();
          this.modal.dismiss();
        },
          error => {
            Swal.fire('حدثت مشكلة', error, 'error');
            this.saving = false;
          });
      }
    }
  }



}
