import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from './baseRepository.service';
import { SchoolStageModel } from '../models/schoolStage.model';

@Injectable({
    providedIn: 'root'
})
export class SchoolStageRepository extends BaseRepository<SchoolStageModel> {
    constructor(protected http: HttpClient) {
        super(http, "SchoolStages")
    }
}
