import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HouseModel } from '../models/house.model';

@Injectable({
    providedIn: 'root'
})
export class BaseRepository<T> {
    constructor(protected http: HttpClient, endpointName: string ) {
        this.apiUrl=environment.urls.appUrl+endpointName+"/";
    }
    apiUrl: string;
    public List():Observable<T[]> {
        return this.http.get<T[]>(this.apiUrl + 'GetAll',{ withCredentials: true });
    }
    public GetAll() {
        return this.http.get(this.apiUrl + 'GetAll',{ withCredentials: true }).pipe(map(res=>res));
    }
    public GetChildPhoto(id) {
        return this.http.get(this.apiUrl + 'GetChildPhoto?childId='+id,{ withCredentials: true }).pipe(map(res => res));
    }
    public Delete(entity) {
        return this.http.delete(this.apiUrl +'Delete?id='+entity,{ withCredentials: true }).pipe(map(res => res));
    }

    public Update(entity) {
        return this.http.put(this.apiUrl + 'Update',entity,{ withCredentials: true }).pipe(map(res => res));
    }
    public Add(entity) {
        return this.http.post(this.apiUrl + 'Add',entity,{ withCredentials: true }).pipe(map(res => res));
    }
    public Search(entity) {
        return this.http.post(this.apiUrl + 'Search',entity,{ withCredentials: true }).pipe(map(res => res));
    }
    public Get(entity){
        return this.http.get(this.apiUrl + 'Get?id='+ entity,{ withCredentials: true }).pipe(map(res => res));
    }
    public GetBirthdays(){
        return this.http.get(this.apiUrl + 'GetBirthdays',{ withCredentials: true }).pipe(map(res => res));
    }
    public PostToOtherApi(body){
        return this.http.get('http://51.75.67.142:6075/api/report?from_date='+body['from_date'] + '&to_date='+body['to_date']).pipe(map(res => res));
    }
}
