import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProjectModel } from '../models/project.model';
import { environment } from 'src/environments/environment';
import { BaseRepository } from './baseRepository.service';
import { ChildrenModel } from '../models/children.model';

@Injectable({
    providedIn: 'root'
})
export class ChildrenRepository extends BaseRepository<ChildrenModel> {
    constructor(protected http: HttpClient) {
        super(http, "Children")
    }
    public GetBirthdaysByMonth(month){
        return this.http.get(this.apiUrl + 'GetBirthdaysByMonth?month='+ month,{ withCredentials: true }).pipe(map(res => res));
    }
    public GetAllNotDepatured(){
        return this.http.get(this.apiUrl + 'GetAll?filterDepartured=true',{ withCredentials: true }).pipe(map(res => res));
    }
    // public GetBirthdays(){
    //     return this.http.get(this.apiUrl + 'GetBirthdays').pipe(map(res => res));
    // }
}
