import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import { BaseRepository } from './repositories/baseRepository.service';
import { DonorTypeRepository } from './repositories/donorTypeRepository.service';
import { HouseRepository } from './repositories/houseRepository.service';
import { MaritalStatusRepository } from './repositories/maritalStatusRepository.service';
import { ProjectRepository } from './repositories/projectRepository.service';
import { ReceiptCategoryRepository } from './repositories/receiptsCategoriesRepository.service';
import { SchoolStageRepository } from './repositories/schoolStageRepository.service';
import { SponsorRepository } from './repositories/sponsorRepository.service';
import { SupervisorRepository } from './repositories/supervisorRepository.service';
import { UserRepository } from './repositories/userRepository.service';
import {PaymentTypeRepository} from './repositories/paymentTypeRepository.service';
import {ChildrenRepository} from './repositories/childrenRepository.service';
import { PaymentDetailRepository } from './repositories/paymentDetailRepository.service';
import { PaymentRepository } from './repositories/paymentRepository.service';
import { AuthenticationService } from './repositories/authentication.service';

@NgModule({
  imports: [
    HttpClientModule, CommonModule
  ],
  declarations: [],
  exports: [
    HttpClientModule
  ]
})

export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        BaseRepository,
        UserRepository,
        HouseRepository,
        SponsorRepository,
        SupervisorRepository,
        ReceiptCategoryRepository,
        MaritalStatusRepository,
        SchoolStageRepository,
        DonorTypeRepository,
        ProjectRepository,
        PaymentTypeRepository,
        ChildrenRepository,
        PaymentDetailRepository,
        PaymentRepository,
        AuthenticationService
      ]
    };
  }
}
