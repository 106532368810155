import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router'


import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


import { ChildrenModel } from 'src/app/data/models/children.model';

import {PaymentModel} from 'src/app/data/models/payment.model';

import {SponsorModel} from 'src/app/data/models/sponsor.model';

import { ChildSponsorModel } from 'src/app/data/models/childSponsor.model';

import { PaymentDetailModel } from 'src/app/data/models/paymentDetail.model';
import {PaymentDetailRepository} from 'src/app/data/repositories/paymentDetailRepository.service';

import Swal from 'sweetalert2';
import { variable } from '@angular/compiler/src/output/output_ast';

import { DateValueComponent } from '../table-values/date-value.component';
// import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'payment-detail',
  templateUrl: './paymentdetail-table.component.html',
  styleUrls: ['./paymentdetail-table.component.scss']
})
export class PaymentDetailComponent implements OnInit {

  dataSource: PaymentDetailModel[] = []
  
  dataForm: FormGroup;
  saving: boolean = false;
  isEdit: boolean = false;
  title: string = "Add New"
  selectedItem: ChildSponsorModel;
  modal: NgbModalRef;


  @Input()
  sponsorId: number;

  @Input()
  sponsorName: string;

  @Input()
  childId: number;


  sub: any;


  @ViewChild('dataModal') dataModal;
  constructor(private route: ActivatedRoute, 
    private paymentDetailRepo: PaymentDetailRepository,
    public fb: FormBuilder, public modalService: NgbModal
    ) { }

  ngOnInit(): void {    
    this.GetDataSource();
  }

  private GetDataSource(){
    this.paymentDetailRepo.GetBySponsorAndChild(this.childId, this.sponsorId).subscribe(res => {
        var data = res as PaymentDetailModel[];
        
        data.forEach((sd) => {
          sd.sponsorName = this.sponsorName;
        });

        this.dataSource = data;
      });
  }

  settings = {
    mode: 'external',
    defaultStyle: false,
    attr:{
      class: 'table table-bordered'
    },
    columns: {
      id: {
        title: '#',
        filter: false,
      },
      sponsorName:{
        title: 'اسم الكفيل',
        filter: false
      },
      paymentShare:{
        title: 'المبلغ',
        filter: false
      },
      receiptDate:{
        title: "التاريخ",
        type: "custom",
        renderComponent: DateValueComponent,
        filter: false
      }
      
    },
    actions: {
      columnTitle: "", 
      add: false, edit: false, delete: false,  
      position: 'right', 
    },
    add: {
      addButtonContent: 'إضافة',
    },
    edit: {
      editButtonContent: '<i data-feather="edit" appFeatherIcon class="btn-icon-prepend"></i> تعديل'},
      delete: {
        deleteButtonContent: '<i data-feather="trash" appFeatherIcon class="btn-icon-prepend"></i> حذف'},
    pager:{
      display: true,
      perPage: 100
    }
  };

  public ItemSelected(item) {
    this.selectedItem = item.data;
  }
  cancel() { this.modalService.dismissAll();  }
}
