import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    {{renderValue}}
  `,
})
export class DateValueComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
    let date = formatDate((new Date(this.value)), 'dd/MM/yyyy','en-US');
    this.renderValue = date;
  }

}