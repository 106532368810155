import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FeahterIconModule } from 'src/app/core/feather-icon/feather-icon.module';
import { NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgSelectModule } from '@ng-select/ng-select';
// Ng-ApexCharts
import { NgApexchartsModule } from "ng-apexcharts";

// Ng2-charts
import { ChartsModule } from 'ng2-charts';


import { DataModule } from 'src/app/data/data.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {DateValueComponent} from 'src/app/views/partials/table-values/date-value.component';

// ngx-quill
import { QuillModule } from 'ngx-quill';

// angular-archwizard
import { ArchwizardModule } from 'angular-archwizard';


import { PaymentDetailComponent } from './paymentdetail-table.component';

@NgModule({
    declarations: [PaymentDetailComponent],
    exports:[PaymentDetailComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        FeahterIconModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgApexchartsModule,
        ChartsModule,
        Ng2SmartTableModule,
        DataModule,
        FontAwesomeModule,
        NgSelectModule,
        QuillModule.forRoot(),
        ArchwizardModule,
        
    ]
})
export class PaymentDetailTableModule { }
