import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from './baseRepository.service';
import { ChildSponsorModel } from '../models/childSponsor.model';

@Injectable({
    providedIn: 'root'
})
export class ChildSponsorRepository extends BaseRepository<ChildSponsorModel> {
    constructor(protected http: HttpClient) {
        super(http, "ChildSponsor")
    }
    public GetBySponsorId(id, active: boolean){
        return this.http.get(this.apiUrl + 'GetBySponsorId?id='+ id + "&active=" + active,{ withCredentials: true }).pipe(map(res => res));
    }
    public GetByChildId(id, active: boolean){
        return this.http.get(this.apiUrl + 'GetByChildId?id='+ id + "&active=" + active,{ withCredentials: true }).pipe(map(res => res));
    }
}
